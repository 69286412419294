import type { FC, ReactNode } from 'react'
import { createContext, useCallback, useState } from 'react'

export type OnConnect = (() => void) | undefined

export interface ConnectWalletModalContextType {
  isOpen: boolean

  showModal: () => void
  hideModal: () => void

  onConnect: OnConnect
  updateOnConnect: (onConnect: OnConnect) => void
}

export const ConnectWalletModalContext =
  createContext<ConnectWalletModalContextType>({
    isOpen: false,

    showModal: () => {},
    hideModal: () => {},

    onConnect: () => {},
    updateOnConnect: () => {},
  })

interface ConnectWalletModalProviderProps {
  children: ReactNode
}

const ConnectWalletModalProvider: FC<ConnectWalletModalProviderProps> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [onConnect, setOnConnect] = useState<OnConnect>()

  const showModal = useCallback(() => {
    setIsOpen(true)
  }, [])

  const hideModal = useCallback(() => {
    setIsOpen(false)
  }, [])

  const updateOnConnect = useCallback(
    (onConnect: OnConnect) => {
      setOnConnect(() => onConnect)
    },
    [setOnConnect]
  )

  return (
    <ConnectWalletModalContext.Provider
      value={{
        isOpen,

        showModal,
        hideModal,

        onConnect,
        updateOnConnect,
      }}
    >
      {children}
    </ConnectWalletModalContext.Provider>
  )
}

export default ConnectWalletModalProvider
